import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class OperationService {
  json: any;
  operationEvent = new BehaviorSubject('');
  // url = environment.apiUrl;

  //  public url = "https://gailgasapi-dev.gail.co.in/v1/";
   public url = "https://gailgasapi.gail.co.in/v1/";
  // public url="http://192.168.1.134:2525/";
  //public url = "http://3.109.11.231:2526/"

  constructor(private http: HttpClient) {
    // console.log('API URL:', this.url);

  }
  public dropdownScope(data: any) {
    return this.http.post(`${this.url}png_pratinidhi_reg_form`, data);
  }

  public geoGraphyArea(data: any) {
    return this.http.post(`${this.url}ga_lst`, data);


  }
  public feedback_submit(data: any) {
    return this.http.post(`${this.url}feedback_submit`, data);
  }
  public cng_rates_fun(data: any) {
    return this.http.post(`${this.url}cng_rates`, data);
  }
  public png_rates_fun(data: any) {
    return this.http.post(`${this.url}png_rates`, data);
  }
  public get_Eoi_Detaiols_Fun(data: any) {
    return this.http.post(`${this.url}get_eoi_details`, data);
  }

// personal Details Component API
public event_05_09_2024(data: any) {
  return this.http.post(`${this.url}event_05_09_2024`, data);
}

  // vendor complaint

  public grievancePoCheck(data: any) {
    return this.http.post(`${this.url}grievance_Po_Check`, data);
  }

  public grievance_area(data:any) {
    return this.http.post(`${this.url}grievance_bussiness_area` , data);
  }

  public vendorPoCode(data: any) {
    return this.http.post(`${this.url}vendorcode_check`, data);
  }

  public customer_type() {
    return this.http.get(`${this.url}customer_type`);
  }

  public grevancecomplaintnature() {
    return this.http.get(`${this.url}grievance_complaint_nature`);
  }
  
  // submit vendore
  public grievance_submit(data: any) {
    return this.http.post(`${this.url}grievance_submit`, data);
  }
  
  // otpverification
  public otpverfication(data: any) {
    return this.http.post(`${this.url}grievance_otp_verification`, data);
  }
// trck coamplaints
public track_complaint(data: any) {
  return this.http.post(`${this.url}track_complaint`, data);
} 

// View_complaint

public View_complaint(data: any) {
  return this.http.post(`${this.url}View_complaint`, data);
} 
// /state_lst
public statelst(data: any) {
  return this.http.post(`${this.url}state_lst`, data);
} 

// /country_lst
public countrylst(data: any) {
  return this.http.post(`${this.url}country_lst`, data);
} 





private dataSubject = new BehaviorSubject<any>(null);
  public data$: Observable<any> = this.dataSubject.asObservable();

  sendData(data: any) {
    this.dataSubject.next(data);
  }



}







// vendor complaint


